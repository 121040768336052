<template>
  <div class="space-y-4">
    <div class="flex space-x-2 gap-2 justify-between items-center">
      <div class="flex space-x-2 items-center justify-between w-full">
        <div class="flex items-center gap-2">
          <a-period-accessor label="Período" v-model="filters.period" />

          <a-select
            class="w-96"
            label="Materia"
            :loading="loadings.courseSections"
            :source="options"
            v-model="filters.courseSection" />
        </div>
        <a-dropdown>
          <template #menu>
            <template v-if="filters.courseSection">
              <a-dropdown-item :to="{ name: 'lessons.unnatendances', params: { ...courseSection }}">
                Ver inasistencias
              </a-dropdown-item>
              <a-dropdown-item :to="{ name: 'lessons.studentsNotes', params: { ...courseSection }}">
                Ver Notas
              </a-dropdown-item>
              <!-- <a-dropdown-item @click="modals.graph = true">
                Ver gráficas
              </a-dropdown-item> -->
            </template>
            <a-dropdown-item v-else class="text-center">
              Por favor seleccione una materia
            </a-dropdown-item>
          </template>
        </a-dropdown>
      </div>
    </div>

    <a-table
      toolbar-column-filter
      :columns="columns"
      :source="pagination.data"
      :pagination="pagination"
      @paginate="search({ page: $event, order_by: 'date' })"
      @toolbar(refresh)="search({ page: 1, order_by: 'date' })"
      :loading="loadings.lessons">
      <template #td(evaluated)="{ item }">
        {{ item.evaluated ? 'SI' : 'NO' }}
      </template>
      <template #td(actions)="{ item }">
        <index-table-dropdown
          v-if="!loadings.lessons"
          :lesson="item"
          @register-lesson="setLesson('register', $event)"
          @edit-lesson="setLesson('edit', $event)"
          @registered-class="search({ order_by: 'date' })"
          @mark-evaluated="markEvaluated(item)"
          @delete="search({ order_by: 'date' })" />
      </template>
    </a-table>
    <!-- <graph-view
      :show="modals.graph"
      :course-section="courseSectionModel"
      v-if="filters.courseSection"
      @cancel="modals.graph = false" /> -->
    <register-class
      :show="modals.register"
      :course-section="lesson"
      @registered-class="modals.register = false; search({ page: 1, order_by: 'date' })"
      @cancel="modals.register = false" />
    <edit-class
      :show="modals.edit"
      :course-section="lesson"
      @edited-class="modals.edit = false; search({ page: 1, order_by: 'date' })"
      @cancel="modals.edit = false" />
  </div>
</template>

<script>
import {mapState, mapActions} from 'vuex'
import IndexTableDropdown from './partials/index-table-dropdown.vue'
import RegisterClass from './partials/register-class'
import EditClass from './partials/edit-class'
import moment from '@/filters/moment'

// import GraphView from './chart-view.vue'

export default {
  components: {
    IndexTableDropdown,
    RegisterClass,
    EditClass
  },
  data: () => ({
    filters: {
      period: null,
      courseSection: null,
    },
    lesson: {},
    courseSections: [],
    lessons: { data: [] },
    loadings: {
      courseSections: false,
      lessons: false
    },
    modals: {
      graph: false,
      register:false,
      edit: false
    },
  }),
  computed: {
    ...mapState({
      user: ({ session }) => session.user,
      pagination: state => state.lessons.pagination
    }),
    options() {
      return this.courseSections
        .map(({ course_id, section_id, course: { title }, section: { letter_code }, id }) => ({
          label: `${title}, Sección ${letter_code}`,
          value: id,
          course_id,
          section_id
        }))
    },
    columns() {
      return [
        { title: 'Tema', key: 'subject', },
        // { title: 'Fecha', key: 'date', },
        { title: 'Corte de período', key: 'period_cut', },
        { title: 'Fecha sugerida',key: 'date',  mutate: ({ key }) => key ? moment(key, 'DD-MM-YYYY') : ''},
        { title: 'Fecha de registro',key: 'registered_at',  mutate: ({ key }) => key ? moment(key, 'DD-MM-YYYY') : ''},
        { title: 'Carga de notas',key: 'evaluated_at',  mutate: ({ key }) => key ? moment(key, 'DD-MM-YYYY') : ''},
        { title: '¿Evaluada?', slot: 'evaluated', },
        { title: '% de evaluación', key: 'evaluation_weighting_prc', },
        { title: 'Acciones', slot: 'actions', alignment: 'right', columnFilterable: false },
      ]
    },
    courseSection () {
      const found = this.courseSections.find(current => current.id === this.filters.courseSection)

      if (found) {
        const { course_id, section_id } = found
        return { course_id, section_id }
      }

      return {}
    },
    courseSectionModel() {
      return this.courseSections.find(current => current.id === this.filters.courseSection)
    },
    tooltipGenerateMessage() {

      if (!this.filters.period) {
        return 'Por favor, seleccione un período'
      }

      if (!this.options.length) {
        return 'No hay materias disponibles para generar una clase'
      }

      return 'Generar clase para el período actual'
    }
  },
  methods: {
    ...mapActions({
      fetchLessons: 'lessons/fetchAll'
    }),
    markEvaluated(lesson) {
      this.$confirm({
        message: '¿Desea marcar esta clase como evaluada?',
        button: {
          no: 'No',
          yes: 'Si'
        },
        callback: confirm => {
          if (confirm) {
            this.$repository.lessons
              .evaluate(lesson.id)
              .then(() => {
                this.search({ order_by: 'date' })
                this.$message.success({
                  message: `Se ha marcado la clase como evaluada. `,
                  duration: 3000
                })
              })
              .catch(() => {
                this.$message.warning({
                  message: `Ha ocurrido un error. No se pudo marcar la clase como evaluada.`,
                  duration: 3000
                })
              })
          }
        }
      })
    },
    setLesson(type, event){
      this.lesson = {}
      this.lesson = event
      if(type === 'register') {
        this.modals.register = true
      } else {
        this.modals.edit = true
      }
    },
    fetch(period) {
      this.lessons = { data: [] }
      this.filters.courseSection = this.$coursesection.id
      this.loadings.courseSections = !this.loadings.courseSections
      this.$repository.teachers
        .courses(this.user.teacher.id, { period, limit: 0, with: 'lessons' })
        .then(({ data }) => {
          this.courseSections = data
        })
        .finally(() => this.loadings.courseSections = !this.loadings.courseSections)
    },
    search(query) {
      const found = this.courseSections.find(current => current.id === this.filters.courseSection)
      this.fetchLessons({
        course_id: found.course_id,
        section_id: found.section_id,
        filters: query })
    },
    toggleGenerate () {
      if (this.options.length) {
        this.modals.generate = !this.modals.generate
      }
    }
  },
  watch: {
    'filters.period'(val) {
      if (val) {
        this.fetch(val)
      }
    },
    'filters.courseSection'(courseSection) {
      // this.$router.push({query: {coursesection: this.filters.courseSection}})
      const found = this.courseSections.find(current => current.id === courseSection)
      if (found) {
        this.$coursesection.id = this.filters.courseSection
        this.loadings.lessons = !this.loadings.lessons
        this.fetchLessons({
          course_id: found.course.id,
          section_id: found.section.id,
          filters: {
            order_by: 'date'
          }
        })
        // .then(pagination => {
        //   this.lessons = pagination
        // })
        .finally(() => this.loadings.lessons = !this.loadings.lessons)
      }
    },
  },
  mounted () {
    // this.filters.courseSection = this.$route.query.coursesection
    // if (this.$route.query.coursesection) {
    //   this.filters.courseSection = this.$coursesection.id
    // }
  }
}
</script>
