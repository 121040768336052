<template>
  <div>
    <a-dropdown>

      <a-loader class="w-7 h-7" v-if="isSomethingLoading" />

      <template #menu>
        <a-dropdown-item v-if="lesson.evaluated" :to="{ name: 'lessons.detail', params: { course_id: lesson.course_id, section_id: lesson.section_id, lesson_id: lesson.id }}">
          Ver clase
        </a-dropdown-item>
        <a-dropdown-item v-if="lesson.registered_at" @click="$emit('edit-lesson', lesson )">
          Editar clase
        </a-dropdown-item>
        <a-dropdown-item v-else @click="$emit('register-lesson', lesson )">
          Registrar clase
        </a-dropdown-item>
        <a-dropdown-item :to="{ name: 'lessons.show', params: { id: lesson.id }, query: { coursesection: lesson.course_section_id } }">
          {{ lesson.evaluated ? 'Registrar nota' :  'Registrar inasistencias/retrasos' }}
        </a-dropdown-item>
        <a-dropdown-item v-if="!lesson.evaluated_at" @click="$emit('mark-evaluated')">
          Marcar como evaluada
        </a-dropdown-item>
        <a-dropdown-item v-if="user.isAdmin" @click="confirmDestroy" :disabled="loadings.destroy">
          <div class="flex gap-2 justify-between">
          <span>
            Eliminar
          </span>
            <a-loader class="w-5 h-5" v-if="loadings.destroy" />
          </div>
        </a-dropdown-item>

        <hr>

        <a-dropdown-item
          loading
          :href="$repository.courseSections.reports.lessonRegister({ ...lesson })"
          rel="noreferrer noopener"
          target="_blank">
          Generar reporte
        </a-dropdown-item>

<!--        <register-class-->
<!--          :show="modals.register"-->
<!--          :course-section="lesson"-->
<!--          @registered-class="emiting"-->
<!--          @cancel="modals.register = false" />-->
<!--        <edit-class-->
<!--          :show="modals.edit"-->
<!--          :course-section="lesson"-->
<!--          @edited-class="emiting"-->
<!--          @cancel="modals.edit = false" />-->
      </template>
    </a-dropdown>
  </div>
</template>

<script>
import { mapState } from 'vuex'
// import RegisterClass from './register-class'
// import EditClass from './edit-class'

export default {
  // components: {
  //   RegisterClass,
  //   EditClass
  // },
  props: {
    lesson: { required: true, type: Object }
  },
  data: () => ({
    loadings: {
      destroy: false
    },
    modals: {
      register: false,
      edit: false
    }
  }),
  emits: ['registered-class', 'mark-evaluated'],
  methods: {
    confirmDestroy() {
      this.$message.warning({
        title: 'Estás por eliminar una clase',
        message: '¿Estas seguro de querer continuar con esta acción?',
        buttons: ({ close }) => [
          { title: 'Si, estoy seguro', action: () => {
            this.destroy()
            close()
          }},
          { title: 'Cerrar', action: () => {
            close()
          }},
        ]
      })
    },
    destroy () {
      this.loadings.destroy = !this.loadings.destroy
      this.$repository.lessons
        .delete(this.lesson.id)
        .then(() => {
          this.$emit('delete')
        })
        .finally(() => this.loadings.destroy = !this.loadings.destroy)
    },
    emiting () {
      this.modals.register = false
      this.modals.edit = false
      this.$emit('registered-class')
    }
  },
  watch: {
    'modals.register'(value){
      console.log(value)
    },
    'modals.edit'(value){
      console.log(value)
    }
  },
  computed: {
    ...mapState({
      user: ({ session }) => session.user,
    }),
    isSomethingLoading() {
      return Object.values(this.loadings).some(value => value)
    }
  }
}
</script>
